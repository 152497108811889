import { createRouter, createWebHistory } from 'vue-router'

import color from '@/assets/sass/color.scss'
import store from '@/store'
import { firebase } from '@/firebase'

/**
 * 各routeのmetaに下記の形式で背景画像を必須で設定する
 * background: { hasImage: 背景画像有無のBoolean, color: color.scssに定義された背景色 }
 */
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'top',
      component: loadComponent('Top.vue'),
      meta: {
        title: '脳タイプ診断 - みんなで作る性格診断',
        description: '１８の質問に答えるだけで分かるあなたの脳タイプ。性格などあなたの取扱説明が出来上がります。周りの人との恋愛や仕事などの相性診断も可能。診断は完全無料です。',
        isLP: true,
        background: { hasImage: true, color: color.white_normal_color }
      }
    },
    {
      path: '/love',
      name: 'love',
      component: loadComponent('Love.vue'),
      meta: {
        title: '脳タイプで分かる恋愛相性診断（完全無料）| 90秒で恋愛傾向や相性を診断',
        description: '脳タイプ診断アプリで恋愛相手や結婚相手との「相性度」を診断します。自分や相手の脳タイプを知ることで、どのような人と相性が良いか診断できます。無料の診断アプリで運命の人に出会うきっかけを見つけてください。',
        isLP: true,
        background: { hasImage: true, color: color.white_normal_color }
      }
    },
    {
      path: '/recruit',
      name: 'recruit',
      component: loadComponent('Recruit.vue'),
      meta: {
        title: '脳タイプ診断 研究員を募集！',
        description: '総診断数 45万人超え！脳タイプに基づいた性格診断ツール「脳タイプ診断」を一緒に盛り上げてくれる研究員を募集します。自己理解を深めてもっと成長したい人、研究者にあこがれたことがある人、事業が成長するプロセスを体感したい人などなど。この機会にぜひご応募ください。',
        background: { hasImage: true, color: color.white_normal_color }
      }
    },
    {
      path: '/home',
      name: 'home',
      component: loadComponent('Home'),
      meta: { requiresAuth: true, background: { hasImage: true, color: color.white_normal_color } }
    },
    {
      path: '/login',
      name: 'login',
      component: loadComponent('Login.vue'),
      meta: {
        title: 'ログイン | 脳タイプ診断',
        description: '脳タイプ診断のログイン画面です。',
        background: { hasImage: true, color: color.yellow_pale_color }
      }
    },
    {
      path: '/auth/line',
      name: 'line',
      component: loadComponent('AuthLine.vue'),
      meta: { noindex: true, background: { color: color.white_normal_color } }
    },
    {
      path: '/signup/auth',
      name: 'signup_auth',
      component: loadComponent('SignupAuth.vue'),
      meta: { noindex: true, background: { hasImage: true, color: color.yellow_pale_color } }
    },
    {
      path: '/signup/detail',
      name: 'signup_detail',
      component: loadComponent('SignupDetail.vue'),
      meta: { noindex: true, background: { hasImage: true, color: color.yellow_pale_color } }
    },
    {
      path: '/signup/sended',
      name: 'signup_sended',
      component: loadComponent('SignupAuthMailSended.vue'),
      meta: { noindex: true, background: { hasImage: true, color: color.yellow_pale_color } }
    },
    {
      path: '/forgot',
      name: 'forgot',
      component: loadComponent('ForgotPassword.vue'),
      meta: { noindex: true, background: { hasImage: true, color: color.yellow_pale_color } }
    },
    {
      path: '/forgot/sended',
      name: 'forgot_sended',
      component: loadComponent('ForgotPasswordSended.vue'),
      meta: { noindex: true, background: { hasImage: true, color: color.yellow_pale_color } }
    },
    {
      path: '/mail/action',
      name: 'mail_action',
      component: loadComponent('ResetPassword.vue'),
      meta: { noindex: true, background: { hasImage: true, color: color.yellow_pale_color } }
    },
    {
      path: '/forgot/done',
      name: 'forgot_done',
      component: loadComponent('ResetPasswordDone.vue'),
      meta: { noindex: true, background: { hasImage: true, color: color.yellow_pale_color } }
    },
    {
      path: '/check',
      name: 'check',
      component: loadComponent('Check.vue'),
      meta: {
        title: '診断画面 | 脳タイプ診断',
        description: 'たった18問の質問に答えるだけですぐに自分の性格がわかります。性格、欲求、パートナーや自分の取説など、仕事や恋愛に役立つ結果が診断できます。',
        background: { hasImage: true, color: color.white_normal_color }
      }
    },
    {
      path: '/check/result',
      name: 'check_result',
      component: loadComponent('CheckResult.vue'),
      meta: { requiresAuth: true, background: { hasImage: true, color: color.white_normal_color } }
    },
    {
      path: '/questions',
      name: 'questions',
      component: loadComponent('Questions.vue'),
      meta: { background: { hasImage: true, color: color.white_normal_color } }
    },
    {
      path: '/questions/:qsid/:qid',
      name: 'questions_result',
      component: loadComponent('QuestionsResult.vue'),
      meta: { background: { hasImage: true, color: color.white_normal_color } }
    },
    {
      path: '/profile/code',
      name: 'profile_code',
      component: loadComponent('ProfileCode.vue'),
      meta: { requiresAuth: true, background: { hasImage: true, color: color.yellow_pale_color } }
    },
    {
      path: '/profile/:uid',
      name: 'profile',
      component: loadComponent('Profile.vue'),
      meta: { background: { hasImage: true, color: color.white_normal_color } }
    },
    // Functionsからプロフィールページの初期描画の中身を取得するためのエンドポイント
    {
      path: '/profile/ogp/:uid',
      name: 'profile_ogp',
      component: loadComponent('Profile.vue'),
      meta: { background: { hasImage: true, color: color.white_normal_color } }
    },
    {
      path: '/friends',
      name: 'friends',
      component: loadComponent('Friends.vue'),
      meta: { requiresAuth: true, background: { hasImage: true, color: color.white_normal_color } }
    },
    {
      path: '/friends/recommend',
      name: 'friends_recommend',
      component: loadComponent('FriendsRecommend.vue'),
      meta: { requiresAuth: true, background: { hasImage: true, color: color.yellow_pale_color } }
    },
    {
      path: '/friends/none',
      name: 'friends_none',
      component: loadComponent('FriendsNone.vue'),
      meta: { requiresAuth: true, background: { hasImage: true, color: color.yellow_pale_color } }
    },
    {
      path: '/account',
      name: 'account',
      component: loadComponent('Account.vue'),
      meta: { requiresAuth: true, background: { hasImage: true, color: color.yellow_pale_color } }
    },
    {
      path: '/account/setting',
      name: 'account_setting',
      component: loadComponent('AccountSetting.vue'),
      meta: { requiresAuth: true, background: { hasImage: true, color: color.yellow_pale_color } }
    },
    {
      path: '/book',
      name: 'book',
      component: loadComponent('Book.vue'),
      meta: {
        title: '脳タイプ診断とは？（脳タイプ図鑑） | 脳タイプ診断',
        description: '脳タイプ診断を普段の生活で活用するための応用例をご紹介します。自分のジェンダーへの理解、そして上司部下、恋愛パートナー、家族とのコミュニケーションを円滑にするヒントを見つけてください！',
        background: { hasImage: true, color: color.white_normal_color }
      }
    },
    {
      path: '/book/about',
      name: 'book_about',
      component: loadComponent('BookAbout.vue'),
      meta: {
        title: '脳タイプについて更に詳しく見てみよう！ | 脳タイプ診断',
        description: '脳タイプごとの特徴を更に詳しく紹介しています。それぞれの特性を理解して、チーム作りや、恋愛、家族、パートナーとの関係に役立つ考え方を紹介します。',
        background: { hasImage: true, color: color.yellow_pale_color }
      }
    },
    {
      path: '/book/desire',
      name: 'book_desire',
      component: loadComponent('BookDesire.vue'),
      meta: {
        title: '脳タイプごとの欲求について | 脳タイプ診断',
        description: '脳タイプごとに「重視する欲求」に違いがあることをご存知ですか？ここでは欲求（ポジティブ・ネガティブ）を24種類に分類し、お父さん脳、お母さん脳、少年脳・少女脳の特徴についてご紹介します。',
        background: { hasImage: true, color: color.yellow_pale_color }
      }
    },
    {
      path: '/book/compare',
      name: 'book_compare',
      component: loadComponent('BookCompare.vue'),
      meta: {
        title: '脳タイプを比較しながら見てみよう！ | 脳タイプ診断',
        description: '全8パターンの脳タイプを公開！「なぜ、あの人はこうするんだろう？」「なぜ、こんなことを言うんだろう？」自分に近い人もいるのに全く違う人も。誰もが自分の考え方を基準として、相手との違いに悩みを持っています。そういった「なぜ？」をできるだけ無くしたい。そんな思いから、この診断プログラム開発は始まりました。',
        background: { hasImage: true, color: color.white_normal_color }
      }
    },
    {
      path: '/book/mbti',
      name: 'book_mbti',
      component: loadComponent('BookMbti.vue'),
      meta: {
        title: '「MBTI診断」と「脳タイプ診断」の特徴と違いとは？｜脳タイプ診断',
        description: '16の性格タイプに分類する性格診断「MBTI」と「脳タイプ診断」の特徴と違いを分かりやすく解説します。どちらの診断も、自分の性格タイプを知り、自己理解や他者理解、キャリア選択などに役立ちます。',
        background: { hasImage: true, color: color.white_normal_color }
      }
    },
    {
      path: '/menu',
      name: 'menu',
      component: loadComponent('Menu.vue'),
      meta: { background: { hasImage: true, color: color.white_normal_color } }
    },
    {
      path: '/cancel',
      name: 'cancel',
      component: loadComponent('Cancel.vue'),
      meta: { requiresAuth: true, background: { hasImage: true, color: color.yellow_pale_color } }
    },
    {
      path: '/cancel/auth',
      name: 'cancel_auth',
      component: loadComponent('CancelAuth.vue'),
      meta: { requiresAuth: true, background: { hasImage: true, color: color.yellow_pale_color } }
    },
    {
      path: '/cancel/done',
      name: 'cancel_done',
      component: loadComponent('CancelDone.vue'),
      meta: { noindex: true, background: { hasImage: true, color: color.yellow_pale_color } }
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: loadComponent('Maintenance.vue'),
      meta: { noindex: true, autoTransition: true, background: { hasImage: true, color: color.yellow_pale_color } }
    },
    {
      path: '/error',
      name: 'error',
      component: loadComponent('Error.vue'),
      meta: { noindex: true, autoTransition: true, background: { hasImage: true, color: color.yellow_pale_color } }
    },
    // Functionsからプロフィールページの初期描画の中身を取得するためのエンドポイント
    {
      path: '/view/error',
      name: 'view_error',
      component: loadComponent('Error.vue'),
      meta: { noindex: true, autoTransition: true, background: { hasImage: true, color: color.yellow_pale_color } }
    },
    {
      path: '/notfound',
      name: 'notfound',
      component: loadComponent('NotFound.vue'),
      meta: {
        title: 'お探しのページがみつかりません｜脳タイプ診断',
        description: '',
        noindex: true,
        autoTransition: true,
        background: { hasImage: true, color: color.yellow_pale_color }
      }
    },
    // Functionsからプロフィールページの初期描画の中身を取得するためのエンドポイント
    {
      path: '/view/notfound',
      name: 'view_notfound',
      component: loadComponent('NotFound.vue'),
      meta: {
        title: 'お探しのページがみつかりません｜脳タイプ診断',
        description: '',
        noindex: true,
        autoTransition: true,
        background: { hasImage: true, color: color.yellow_pale_color }
      }
    },
    {
      path: '/noexists',
      name: 'noexists',
      component: loadComponent('NoExists.vue'),
      meta: { noindex: true, background: { hasImage: true, color: color.yellow_pale_color } }
    },
    {
      path: '/notsupported',
      name: 'notsupported',
      component: loadComponent('NotSupported.vue'),
      meta: { noindex: true, autoTransition: true, background: { hasImage: true, color: color.yellow_pale_color } }
    },
    {
      path: '/policy',
      name: 'policy',
      component: loadComponent('Policy.vue'),
      meta: {
        title: 'プライバシーポリシー | 脳タイプ診断',
        description: '脳タイプ診断のプライバシーポリシーです。完全無料でご利用いただける脳タイプ診断の情報の取り扱いについてご確認ください。',
        autoTransition: true,
        background: { hasImage: true, color: color.yellow_pale_color }
      }
    },
    {
      path: '/terms',
      name: 'terms',
      component: loadComponent('Terms.vue'),
      meta: {
        title: '利用規約 | 脳タイプ診断',
        description: '脳タイプ診断の利用規約です。完全無料でご利用いただける脳タイプ診断の運用方針にご理解をお願いいたします。',
        autoTransition: true,
        background: { hasImage: true, color: color.yellow_pale_color }
      }
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/notfound'
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    // ブラウザバック or 戻るボタンを押したときは表示するページの描画後に前回の表示位置にスクロールさせる.
    // 上記以外のページ遷移時にはトップを表示させる
    return new Promise(resolve => {
      if (savedPosition) {
        const isProcessingWatch = store.watch(() => store.getters.isProcessing, (newValue, oldValue) => {
          if (newValue === false && oldValue === true) {
            // 処理中 => 完了になったとき監視をやめて前回の表示位置を返却する
            unwatch()
            resolve(savedPosition)
          }
        })
        const unwatch = () => isProcessingWatch()
      } else {
        resolve({ x: 0, y: 0 })
      }
    })
  }
})

/**
 * viewsのファイルをロード
 * @param {String} name viewsのファイル名
 * @return {String} パスを含むファイル名
 */
function loadComponent (name) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${name}`)
}

/**
 * ページ遷移の分岐処理
 */
router.beforeEach((to, from, next) => {
  // 1. スラッシュ付きリダイレクトの処理
  // ルートパス '/' は除外
  if (to.path !== '/' && !to.path.endsWith('/')) {
    // クエリパラメータとハッシュを保持してリダイレクト
    return next({
      path: to.path + '/',
      query: to.query,
      hash: to.hash,
      replace: true // ブラウザの履歴に追加しない
    })
  }

  // 2. 既存の処理
  const isNotSupportBrowser = to.name !== 'notsupported' && !store.getters.isSupportBrowser
  const autoTransition = to.matched.some(record => record.meta.autoTransition)
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const isToLP = to.matched.some(record => record.meta.isLP)
  const isFromToLP = from.matched.some(record => record.meta.isLP)
  const background = to.meta.background

  // 認証後の遷移先として現在のURLを保存する
  const redirectURL = store.getters.redirectPath
  if (!redirectURL) store.commit('setRedirectURL', to.path)

  // 初回アクセスのとき or サポート対象外のブラウザのときにチェックを行う
  if (isNotSupportBrowser) analysisBrowser(to, next)

  const isAuthProcessing = store.getters.isAuthProcessing
  const isMaintenance = store.getters['maintenances/isMaintenance']

  // 判定用のデータ取得
  const auth = firebase.auth().currentUser
  const emailVerified = store.getters['authPassword/emailVerified']
  const isPasswordProvider = store.getters['authPassword/isPasswordProvider']
  const uid = auth && (emailVerified && isPasswordProvider || !isPasswordProvider) ? auth.uid : null

  // 友達からシェアされたURLの場合パラメータをローカルストレージに保存する
  if (to.query.sharedUID) localStorage.setItem('addFriendUID', to.query.sharedUID)

  // viewsのmountedが終わるまで処理中に
  if (to.name !== from.name) store.commit('setProcessing', true)
  if (to.name !== from.name) store.commit('setBackground', background)

  // LINEで外部ブラウザ遷移用パラメータがなければ付与した上で次の処理に飛ばす
  if (to.name !== 'line' && !to.query.openExternalBrowser) {
    return next({
      name: to.name,
      params: to.params,
      query: { ...to.query, openExternalBrowser: 1 }
    })
  }

  // 認証、ユーザー取得状況に応じてページ遷移
  if (isAuthProcessing || autoTransition) {
    if (autoTransition) {
      return next()
    } else {
      // Auth処理中の場合はパラメータ情報が失われないようstoreに保存し遷移をキャンセルさせる
      store.commit(
        'setQueryParameter',
        to.query ?
          `?${Object.keys(to.query)
            .map(key => `${key}=${to.query[key]}`)
            .join('&')}` :
          ''
      )
      return next(false)
    }
  } else if (isMaintenance) {
    // メンテナンスモードの場合はメンテナンスページに飛ばす
    return next({ name: 'maintenance' })
  } else if (uid) {
    // ログインしている場合
    // homeからLPページに遷移しようとした場合再びhomeに戻るので遷移をやめる
    if (isToLP && from.name === 'home') {
      store.commit('setProcessing', false)
      return next(false)
    } else {
      return isToLP ?
        next({ name: 'home', query: to.query }) :
        next()
    }
  } else {
    // 未ログインの場合
    // LPページから認証必須ページへの遷移はLPページのままにしたいので遷移を中断する
    if (requiresAuth && isFromToLP) {
      store.commit('setProcessing', false)
      return next(false)
    } else {
      return !requiresAuth ?
        next() :
        next({ name: 'top', query: to.query })
    }
  }
})

/**
 * ブラウザの解析をする
 * WebViewのときはOS毎に以下のように処理させる
 * iOS => サポート対象外ブラウザのページに遷移させる
 * android => 閲覧しようとしたページをchromeで開く
 * @see "https://docs.google.com/document/d/1WITn598mniIV7k2ZmBJT8wLO6vOhI41tXp8GC-Qihg0"
 *
 * @param {Object} to 遷移先ページ
 * @param {Object} next 次の処理
 */
const analysisBrowser = (to, next) => {
  const userAgent = parseUserAgent()
  store.commit('setSupportBrowser', userAgent.isSupportBrowser)

  if (userAgent.isIOSWebView) {
    next({ name: 'notsupported' })
  } else if (userAgent.isAndroidWebView) {
    openAndroidChrome(to.path)
  } else {
    // なにもしない.
  }
}

/**
 * アクセスされたUserAgentを解析する
 *
 * @return {Object} UserAgentをパースした結果 { isAndroidWebView: andoroidのWebViewか, isIOSWebView: iOSのWebViewか, isSupportBrowser: サポートブラウザか}
 */
const parseUserAgent = () => {
  const rowUa = window.navigator.userAgent || ''
  const ua = rowUa.toLowerCase().trim()

  const isAndroid = /android/.test(ua)
  const isIOS = /iphone|ipod|ipad/.test(ua)
  const isWebView = /fb_iab|fban|instagram|messenger|line\/|yjapp/.test(ua)

  const isAndroidWebView = isAndroid && isWebView
  const isIOSWebView = isIOS && isWebView

  return {
    isAndroidWebView: isAndroidWebView,
    isIOSWebView: isIOSWebView,
    isSupportBrowser: !(isIOSWebView || isAndroidWebView)
  }
}

/**
 * 閲覧しようとしたページをandroidのchromeで表示する
 *
 * @param {String} path 閲覧しようとしたページのパス
 */
const openAndroidChrome = path => {
  const hostPath = location.host + path
  window.location.href = 'intent://' + hostPath + '/#Intent;scheme=https;action=android.intent.action.VIEW;package=com.android.chrome;end'
}


export default router
